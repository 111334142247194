// src/services/productService.js
// With React you have to prefix the environment variable with REACT_APP_
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAllExchangeProducts = async (page = 1, limit = 10, filterTags = []) => {

  const queryParams = new URLSearchParams({
    page,
    limit,
    types: filterTags.join(','), // Pass filterTags as a comma-separated string
  }).toString();

  const url = `${API_URL}/products/public?${queryParams}`;
  console.log(`Fetching from URL: ${url}`);

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch products');
    }
    const data = await response.json();
    //console.log(`Fetched products: ${JSON.stringify(data.products)}`);
    return data;
  } catch (error) {
    console.error('Error fetching all exchange products:', error);
    throw error;
  }
};