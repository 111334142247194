import React, { useContext, useRef, useState, useCallback, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Container, Button, ButtonGroup } from 'react-bootstrap';
import { ProductsContext } from '../contexts/ProductsContext';
import ProductCard from '../components/ProductCard';
import Masonry from 'react-masonry-css';
import './Exchange.css';

const Exchange = () => {
  const { products, loading, error, hasMore, setHasMore, setCurrentPage, loadMoreProducts, resetProducts } = useContext(ProductsContext);
  const [selectedTags, setSelectedTags] = useState([]);
  const observer = useRef();

  // Tag options
  const tags = ['showcase'];

  // Reset products when filterTags change
  useEffect(() => {
    resetProducts(); // Clear the existing products
    //console.log(`Filters updated to: ${filterTags.join(', ')}`);
    setCurrentPage(1); // Reset page to 1
    setHasMore(true); // Reset hasMore flag

    // Trigger initial load for the updated filters
    loadMoreProducts(tags);
  }, []); // Re-trigger when filterTags change

  // Intersection Observer to trigger loadMoreProducts when reaching end of the list
  const lastProductRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreProducts(tags);
      }
    });

    if (node) observer.current.observe(node);
  }, [loading, hasMore, loadMoreProducts]);

  if (error) return <div>Error: {error}</div>;

  // Masonry breakpoints for responsiveness
  const masonryBreakpoints = {
    default: 4, // Number of columns for default (large screens)
    1400: 3,    // Number of columns for tablets
    768: 2,     // Number of columns for smaller tablets
    480: 1      // Number of columns for mobile
  };

  return (
    <Container>
      <ToastContainer />
      <div className="home-page-wrapper">
        <div className="home-container">
          <div style={{padding:'30px'}}>
            <h1>Showcase</h1>
          </div>
          <div>
            {/* Tag Filter Buttons */}
            {/*
            <div className="tag-filter">
              <ButtonGroup>
                {tags.map((tag) => (
                  <Button
                    key={tag}
                    variant={selectedTags.includes(tag) ? 'primary' : 'outline-primary'}
                    onClick={() => toggleTag(tag)}
                  >
                    {tag.charAt(0).toUpperCase() + tag.slice(1)}
                  </Button>
                ))}
              </ButtonGroup>
            </div>

            {/* Masonry Grid */}
            <Masonry
              breakpointCols={masonryBreakpoints}
              className="masonry-grid"
              columnClassName="masonry-grid-column"
            >
              {products.map((product, index) => (
                <div
                  key={product._id}
                  ref={products.length === index + 1 ? lastProductRef : null}
                >
                  <ProductCard product={product} />
                </div>
              ))}
            </Masonry>
            {loading && <div>Loading more products...</div>}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Exchange;
