// src/contexts/ProductsContext.js
import React, { createContext, useState, useEffect } from 'react';
import { fetchAllExchangeProducts } from '../services/productService';

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  //const [filterTags, setFilterTags] = useState([]);

  const resetProducts = () => {
    console.log("Resetting products state");
    setProducts([]); // Clear the products array
    setCurrentPage(1); // Reset the current page
    setHasMore(true); // Reset the 'has more' flag
    setError(null); // Clear any existing errors
  };

  // Load more products function
  const loadMoreProducts = async (filterTags, limit = 10) => {
    if (loading) return; // Prevent re-triggering if already loading

    console.log(`Attempting to load page ${currentPage} with limit ${limit}`);
    setLoading(true);

    try {
      const { products: newProducts, page } = await fetchAllExchangeProducts(currentPage, limit, filterTags);

      console.log(`Loaded ${newProducts.length} products for page ${page}`);
      setProducts((prevProducts) => [...prevProducts, ...newProducts]);

      // Determine if more products are available
      if (newProducts.length < limit) {
        console.log("No more products to load.");
        setHasMore(false);
      } else {
        setCurrentPage(page + 1); // Increment page for next load
      }

      // Explicitly return the fetched products
      return newProducts;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProductsContext.Provider value={{ products, loading, error, hasMore, setHasMore, setCurrentPage, loadMoreProducts, resetProducts }}>
      {children}
    </ProductsContext.Provider>
  );
};
