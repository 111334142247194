import React from 'react';
import { Container } from 'react-bootstrap';
import './Privacy.css';

function PrivacyPolicy() {
  return (
    <>
      <Container>
        <div className="home-page-wrapper">
          <p className="hint-container-title">PRIVACY POLICY</p>
          <p className="hint-container-title">Effective Date: Nov 23, 2024</p>
          <p>
            BeamBenders (“we,” “us,” or “our”) respects your privacy and is committed to protecting the information you share with us. This Privacy Policy
            describes how we collect, use, and share your information when you use BeamBenders.com (“Platform”). By using the Platform, you consent to the
            practices described in this Privacy Policy.
          </p>

          <ol className="terms-list">
            <li>
              <strong>Information We Collect</strong>
              <ul>
                <li>
                  <strong>Email Address:</strong> We collect your email address when you choose to log in to the Platform via Google for authentication purposes.
                </li>
                <li>
                  <strong>Name:</strong> Your Gmail display name. 
                </li>
                <li>
                  <strong>Store Address:</strong> If you are a store owner, your store address is collected for internal purposes but is not made publicly available.
                </li>
                <li>
                  <strong>Store Name:</strong> Your store name is collected so that it can be displayed to potential buyers.
                </li>
                <li>
                  <strong>Publicly Shared Information:</strong> Approximate location of your store may be displayed publicly. Your email address and exact store
                  address will not be exposed unless explicitly shared by you as part of buyer-seller communication.
                </li>
                <li>
                  <strong>Analytics Data:</strong> We collect analytics data to improve our Platform. This includes device type, browser information, and usage
                  patterns. We don't use any 3rd party analytics platform. Your IP address and other PII information is not collected.
                </li>
                <li>
                  <strong>Error and Crash Reporting:</strong> We use Sentry to collect information about application crashes for debugging and improving our
                  services. Collection of PII data is disabled. 
                </li>
              </ul>
            </li>
            <li>
              <strong>How We Use Your Information</strong>
              <ul>
                <li>To provide and improve our services.</li>
                <li>To authenticate users and secure the Platform.</li>
                <li>To communicate with you about your account or transactions.</li>
                <li>To analyze usage patterns and improve user experience.</li>
                <li>To resolve disputes and enforce our Terms of Service.</li>
              </ul>
            </li>
            <li>
              <strong>How We Share Your Information</strong>
              <ol className="nested-list">
                <li>
                  <strong>Public Information</strong>
                  <ul>
                    <li>Only the approximate location of your store is shared publicly.</li>
                  </ul>
                </li>
                <li>
                  <strong>Buyer-Seller Communication</strong>
                  <ul>
                    <li>If a potential buyer chooses to communicate with a store owner, the buyer’s information will be shared directly with the store owner for transactional
                    purposes. Only the buyer's email and the product they are interested in are shared with the seller.</li>
                  </ul>
                </li>
                <li>
                  <strong>Third-Party Services</strong>
                  <ul>
                    <li>We use Sentry for crash reporting. Data shared with Sentry is limited to what is necessary for error and crash analysis. PII information is not collected.</li>
                    <li>We use Stripe to process transactions, and all transaction-related privacy policies are handled through Stripe. Please refer to <a href="https://stripe.com/en-ca/privacy" target="_blank" rel="noopener noreferrer">Stripe’s Privacy Policy</a>{' '} for more details.</li>
                  </ul>
                </li>
                <li>
                  <strong>Email Sending Authorization</strong>
                  <ul>
                    <li>If you choose, you can authorize the BeamBenders Platform to send emails using your Gmail account. This requires incremental authorization with Google to allow the Platform to send emails on your behalf. For example, when a user purchases a product from a seller, an email can be sent from the seller’s email address to the buyer with order details. Store owners can also send emails to potential buyers who are on the waitlist. This feature is entirely optional and requires your explicit consent.</li>
                  </ul>
                </li>
                <li>
                  <strong>Legal Requirements</strong>
                  <ul>
                    <li>We may disclose your information if required by law or if necessary to protect our rights, property, or safety.</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <strong>Data Storage and Security</strong>
              <p>We implement appropriate technical measures to secure your information. For instance, the Platform uses TLS encryption site-wide to protect users from man-in-the-middle attacks. Additionally, all data stored on MongoDB Atlas is encrypted both in transit and at rest, leveraging AES-256 encryption for data security.  </p>
            </li>
            <li>
              <strong>Your Rights and Choices</strong>
              <p>
                You may update or delete your information by contacting us at <a href="mailto:support@beambenders.com">support@beambenders.com</a>. Some information may be retained for legal or business
                purposes.
              </p>
            </li>
            <li>
              <strong>Changes to This Privacy Policy</strong>
              <p>
                We may update this Privacy Policy from time to time. Continued use of the Platform constitutes acceptance of the updated Privacy Policy.
              </p>
            </li>
            <li>
              <strong>Contact Us</strong>
              <p>
                If you have any questions, email us at <a href="mailto:support@beambenders.com">support@beambenders.com</a>.
              </p>
            </li>
          </ol>
        </div>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
