import React, { useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../contexts/StoreContext';
import { ProductsContext } from '../contexts/ProductsContext';
import ProductCard from '../components/HomeProductCard';
import StoreCard from '../components/StoreCard';
import Masonry from 'react-masonry-css';
import './Home.css';

const exchangeUrl = process.env.REACT_APP_EXCHANGE_URL;
const showcaseUrl = process.env.REACT_APP_SHOWCASE_URL;
const phosphorUrl = process.env.REACT_APP_PHOSPHOR_URL;

const Home = () => {
  const { stores, loading, error } = useContext(StoreContext);
  const { loadMoreProducts, resetProducts } = useContext(ProductsContext);
  const navigate = useNavigate();

  const [showcaseProducts, setShowcaseProducts] = useState([]);
  const [saleProducts, setSaleProducts] = useState([]);
  const [loadingShowcase, setLoadingShowcase] = useState(false);
  const [loadingSale, setLoadingSale] = useState(false);

  useEffect(() => {
    resetProducts(); // Clear existing products before loading
    fetchShowcaseProducts();
    fetchSaleProducts();
  }, []);

  const fetchShowcaseProducts = async () => {
    setLoadingShowcase(true);
    try {
      const showcase = await loadMoreProducts(['showcase'], 12); // Fetch 12 showcase products
      console.log('showcase', showcase);
      setShowcaseProducts(showcase || []);
    } catch (err) {
      console.error('Error loading showcase products:', err);
    } finally {
      setLoadingShowcase(false);
    }
  };

  const fetchSaleProducts = async () => {
    setLoadingSale(true);
    try {
      const sale = await loadMoreProducts(['sale'], 12); // Fetch 12 sale products
      setSaleProducts(sale || []);
    } catch (err) {
      console.error('Error loading sale products:', err);
    } finally {
      setLoadingSale(false);
    }
  };

  // Masonry breakpoints for responsiveness
  const masonryBreakpoints = {
    default: 4, // Number of columns for default (large screens)
    1400: 3,    // Number of columns for tablets
    768: 2,     // Number of columns for smaller tablets
    480: 1      // Number of columns for mobile
  };

  const features = [
    {
      title: 'Exchange Marketplace',
      icon: '/exchange.svg',
      details:
        'A curated marketplace for retro electronics, mod kits, and accessories, offering online purchases or local pickups.',
      link: exchangeUrl,
    },
    {
      title: 'Retro Showcase',
      icon: '/spotlight.svg',
      details:
        'Display your collection, mods, or creations. Share your story and connect with fellow retro enthusiasts.',
      link: showcaseUrl,
    },
    {
      title: 'Admin Portal',
      icon: '/portal.svg',
      details:
        'An admin portal for retro enthusiasts to create stores, sell products, and showcase their work.',
      link: phosphorUrl,
    },
  ];

  return (
    <>
      <Container>
        <ToastContainer />
        <div className="home-page-wrapper">
          <div className="home-container">
            <p>
              <img className="home-logo-gif" src="./beambenders_logo.gif" />
            </p>
            <p>
              <img className="home-logo" src="./beambenders_logo.png" />
            </p>
            <p>(<i><b>n.</b></i>) Perfecting the art of bending electrons.</p>
          </div>

          <Row className="mb-3">
            {features.map((feature, index) => (
              <Col key={index} xs={12} md={12} lg={4} className="mb-4">
                <Card
                  className="feature-card h-100"
                  onClick={() => window.open(feature.link, '_blank')}
                >
                  <Card.Body className="d-flex flex-column">
                    {/* Title Row */}
                    <Card.Title className="mb-3">{feature.title}</Card.Title>

                    {/* Icon + Text Row */}
                    <div className="icon-text-row d-flex align-items-center mb-3">
                      <div className="icon-container">
                        <img
                          src={feature.icon}
                          alt={feature.title}
                          className="feature-icon"
                        />
                      </div>
                      <div className="text-container ms-3">
                        <Card.Text>{feature.details}</Card.Text>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          {/* Display Store Cards */}
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <h1 style={{ color: '#e53376' }}>Retro Caves</h1>
          </div>
          <div className="store-list">
            {loading && <p>Loading stores...</p>}
            {error && <p>{error}</p>}
            {!loading && !error && (
              <Row>
                {stores &&
                  stores.map((store, index) => (
                    <Col key={index} xs={12} md={12} lg={4}>
                      <StoreCard store={store} onImageClick={() => console.log(store.name)} />
                    </Col>
                  ))}
              </Row>
            )}
          </div>

          {/* Showcase Section */}
          <div style={{ padding: '30px', textAlign: 'center' }}>
            <h1 style={{ color: '#e53376' }}>Latest Showcase</h1>
          </div>
          {loadingShowcase ? (
            <p>Loading showcase products...</p>
          ) : (
            <Masonry
              breakpointCols={masonryBreakpoints}
              className="masonry-grid"
              columnClassName="masonry-grid-column"
            >
              {showcaseProducts.map((product, index) => (
                <div key={product._id}>
                  <ProductCard product={product} />
                </div>
              ))}
            </Masonry>
          )}

          {/* See More Button for Showcase */}
          <div className="see-more-container text-center mt-4">
            <Button
              variant="btn btn-primary"
              onClick={() => {
                resetProducts();
                navigate('/showcase');
              }}
            >
              Show more showcase
            </Button>
          </div>

          {/* Sale Section */}
          <div style={{ padding: '30px', textAlign: 'center' }}>
            <h1 style={{ color: '#e53376' }}>Exchange Exclusives</h1>
          </div>
          {loadingSale ? (
            <p>Loading sale products...</p>
          ) : (
            <Masonry
              breakpointCols={masonryBreakpoints}
              className="masonry-grid"
              columnClassName="masonry-grid-column"
            >
              {saleProducts.map((product, index) => (
                <div key={product._id}>
                  <ProductCard product={product} />
                </div>
              ))}
            </Masonry>
          )}

          {/* See More Button for Sale */}
          <div className="see-more-container text-center mt-4">
            <Button
              variant="btn btn-primary"
              onClick={() => {
                resetProducts();
                navigate('/exchange');
              }}
            >
              Show more exclusives
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
