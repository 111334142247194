// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nested-list {
  counter-reset: sub-item; /* Reset counter for nested list */
  list-style: none; /* Remove default list style */
  padding-left: 20px; /* Add padding for nesting */
}

.nested-list > li {
  counter-increment: sub-item; /* Increment counter for each list item */
}

.nested-list > li::before {
  content: "3." counter(sub-item) " "; /* Add parent number and sub-item */
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/Privacy.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB,EAAE,kCAAkC;EAC3D,gBAAgB,EAAE,8BAA8B;EAChD,kBAAkB,EAAE,4BAA4B;AAClD;;AAEA;EACE,2BAA2B,EAAE,yCAAyC;AACxE;;AAEA;EACE,mCAAmC,EAAE,mCAAmC;EACxE,iBAAiB;AACnB","sourcesContent":[".nested-list {\n  counter-reset: sub-item; /* Reset counter for nested list */\n  list-style: none; /* Remove default list style */\n  padding-left: 20px; /* Add padding for nesting */\n}\n\n.nested-list > li {\n  counter-increment: sub-item; /* Increment counter for each list item */\n}\n\n.nested-list > li::before {\n  content: \"3.\" counter(sub-item) \" \"; /* Add parent number and sub-item */\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
